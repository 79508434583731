export const languageAz = {
  translation: {
    user: {
      fields: {
        firstname: 'Ad',
        lastname: 'Soyad',
      },
    },
    languages: {
      languages: 'Dillər',
      azerbaijan: 'Azərbaycan',
      english: 'İngilis',
      turkey: 'Türkiyə',
    },
    countries: {
      choose: 'Ölkə seçin',
      country: 'Country',
      tr: 'Turkey',
      us: 'USA',
    },
    common: {
      all: 'Hamısı',
      details: 'Ətraflı',
      generalinfo: 'Ümumi məlumat',
      exportAsExcel: 'Excel export',
      processing: 'Əməliyyat aparılır',
      pieces: 'Ədəd',
      piecesWithCount: '{{count}} ədəd',
      piecesWithCount_plural: '{{count}} ədəd',
      kgWithCount: '{{count}} kq',
      confirm: 'Təsdiqlə',
      lineSelectedWithCount: '{{count}} sətir seçilib',
      lineSelectedWithCount_plural: '{{count}} sətir seçilib',
      weightWithKG: '{{weight}} kq',
      operationSucceed: 'Əməliyyat müvəffəqiyyətlə sona çatdı',
      document: 'Sənəd',
      tin: 'VÖEN',
      notExists: 'Mövcüd deyil',
      notEntered: 'Daxil edilməyib',
      prices: 'Qiymətlər',
      sizes: 'Ölçülər',
      currentMonth: 'Cari ay',
      approved: 'Təsdiqlənib',
      notconfirmed: 'Təsdiqlənməyib',
      executed: 'İcra edilib',
      notexecuted: 'İcra edilməyib',
      questionConfirm: 'Əməliyyatı etməyinizə əminsinizmi?',
      questionConfirmInfo: 'Əməliyyatı etdikdən sonra məlumatların geri qaytarılması mümkün olmayacaq.',
      branch: 'Filial',
      errors: {
        getRequestFailed: 'Məlumatlar əldə edilə bilmədi',
        networkFailed: 'Şəbəkə ilə əlaqə qurula bilmədi',
        operationFailed: 'Əməliyyat aparıla bilmədi',
        browserError: 'Brauzerdə xəta baş verdi.',
        templateError: 'Şablon əldə edilə bilmədi.',
        loginFailed: 'İstifadəçi məlumatları yanlış daxil edilib.',
        userBanned: 'Sistemə girişinizə müvəqqəti olaraq məhdudiyyət qoyulmuşdur.',
        parcelNotSelected: 'Koli seçilməyib.',
        dataNotFound: 'Məlumat tapılmadı.',
        dnsNotFound: 'Bəyannamə tapılmadı.',
      },
      fields: {
        id: 'Kod',
        no: 'No',
        name: 'Ad',
        assigned: 'Təhkim olunub',
        trackCode: 'İzləmə kodu',
        createdAt: 'Yaradılıb',
        closedAt: 'Bağlanılıb',
        object: 'Obyekt',
        date: 'Tarix',
        weight: 'Çəki',
        quantity: 'Miqdar',
        type: 'Tip',
        barcode: 'Barkod',
        executor: 'İcraçı',
        shop: 'Mağaza',
        price: 'Qiymət',
        height: 'Hündürlük',
        width: 'Uzunluq',
        otp: 'OTP',
        operationDate: 'Əməliyyat tarixi',
        authorName: 'Icraçı adı',
        authorId: 'Icraçı kodu',
        depth: 'En',
        description: 'Açıqlama',
        comment: 'Şərh',
        tin: 'VÖEN',
        totalCount: 'Ümumi say',
        totalWeight: 'Ümumi çəki',
        totalPrice: 'Ümumi qiymət',
        count: 'Say',
        plan: {
          id: 'Tarif kodu',
          name: 'Tarif',
        },
        user: {
          id: 'M. kodu',
          name: 'Müştəri',
        },
        status: {
          id: 'Status kodu',
          name: 'Status',
        },
        startedAt: 'Başlama tarixi',
        endedAt: 'Bitmə tarixi',
        country: 'Ölkə seçin',
        branch: 'Filial seçin',
      },
      placeholders: {
        barcode: 'Barkod daxil edin',
        status: 'Status seçin',
        user: 'Müştəri seçin',
        height: 'Hündürlük daxil edin',
        width: 'Uzunluq daxil edin',
        depth: 'En daxil edin',
        plan: 'Tarif seçin',
        chooseShop: 'Mağaza seçin',
        enterShop: 'Mağaza daxil edin',
        quantity: 'Say daxil edin...',
        tin: 'VÖEN daxil edin',
        description: 'Açıqlama daxil edin',
        comment: 'Şərh daxil edinş...',
        trackCode: 'İzləmə kodu daxil edin...',
      },
      actions: {
        create: 'Yeni',
        createWithName: 'Yeni {{name}} əlavə et',
        accept: 'Qəbul et',
        selectAll: 'Hamısını seç',
        reload: 'Yenilə',
        reset: 'Sıfırla',
        exit: 'Çıxış',
        update: 'Dəyişdir',
        delete: 'Sil',
        close: 'Bağla',
        clear: 'Təmizlə',
        printSticker: 'Stikeri çap et',
        search: 'Axtar',
        uploadDocument: 'Sənəd yüklə',
        downloadDocument: 'Sənədi endir',
        openDocument: 'Sənədi aç',
        markAsRead: 'Oxunmuş et',
        markAsUnread: 'Oxunmamış et',
        trExpComparison: 'TrExp müqayisə',
      },
      read: {
        update: 'Oxunmanı dəyişdir',
        read: 'Oxunmuş',
        unread: 'Oxunmamış',
      },
    },
    declarations: {
      declarations: 'Bağlamalar',
      declaration: 'Bağlama',
      minified: 'Bağlama Minilist',
      new: 'Yeni bağlama',
      unknowns: 'Mübahisəli bağlamalar',
      controversialEdit: 'Mübahisəli bağlamada düzəliş et',
      controversialNew: 'Yeni mübahisəli bağlama',
      controversialAccept: 'Mübahisəli bağlama qəbulu',
      customs: 'Bəyan edilmişlər',
      deleted: 'Silinmiş bağlamalar',
      citizen: 'Vətəndaş bağlaması',
      declared: 'Bəyanlı',
      undeclared: 'Bəyansız',
      count: 'Bağlama sayı',
      enterTrackCode: 'Trak kod daxil edin',
      totalWeight: 'Toplam çəki',
      totalCount: 'Say',
      waybill: 'Yol vərəqəsi',
      waybillProgress: 'Yol vərəqəsi hazırlanır',
      proforma: 'Proforma',
      sticker: 'Stiker',
      accept: 'Bağlamaları qəbul etməyə əminsiniz?',
      accepted: 'Bağlamalar qəbul olundu',
      warehouseInfo: 'Anbar məlumatları',
      acceptUpdateState: 'Bağlamanın statusunu dəyişməyə əminsiniz?',
      acceptAutoCreate: 'Bağlama avtomatik yaradılsın?',
      acceptUpdateRead: 'Bağlamanın oxunma statusunu dəyişməyə əminsiniz?',
      acceptRemoveFromBasket: 'Bağlamanı səbətdən çıxarma əminsiniz?',
      composition: {
        composition: 'Tərkibi',
        liquid: 'Maye',
        other: 'Digər',
      },
      descriptionMessage: {
        start: 'Bağlama',
        middle1: 'tarixində',
        middle2: 'tərəfindən',
        end: 'statusuna keçirilmişdir.',
      },
      actions: {
        updateParcel: 'Kolini dəyiş',
        removeFromBasket: 'Səbətdən çıxar',
        updateBasket: 'Səbəti dəyiş',
        changeBasket: 'Səbət dəyiş',
        addToParcel: 'Koliyə əlavə et',
        addToBasket: 'Səbətə əlavə et',
        printSticker: 'Stikeri çap et',
        removeFromParcel: 'Kolidən çıxar',
        printProform: 'Proforma',
        printProformDetailed: 'Proforma ətraflı',
        printProformEdit: 'Proforma düzəliş',
        statusMap: 'Status xəritəsi',
      },
      fields: {
        trackCode: 'Track kod',
        declared: 'Bəyan',
        isInDns: 'BBS',
        parcel: 'Koli',
        prevBasket: ' Əvvəlki səbət',
        flightName: 'Uçuş',
        name: 'Ad',
        basket: 'Səbət',
        quantity: 'Miqdarı',
        price: 'Qiymət',
        globalTrackCode: 'Qlobal izləmə kodu',
        globalTrackCodeShort: 'Q.İ. kodu',
        shop: 'Mağaza',
        status: 'Bağlama statusu',
        statusShort: 'Bağ. status',
        productPrice: 'Məhsulun qiyməti',
        deliveryPrice: 'Çatdırılma qiyməti',
        partnerName: 'Partnyor adı',
        isSpecial: 'Xüsusi tarifdir',
        isLiquid: 'Mayedir',
        isCommercial: 'Kommersial',
        productType: {
          name: 'Məhsul tipi',
        },
      },
      placeholders: {
        trackCode: 'İzləmə kodu daxil edin',
        parcel: 'Koli seçin',
        basket: 'Səbət seçin',
        globalTrackCode: 'Qlobal izləmə kodunu daxil edin',
        productPrice: 'Məhsulun qiymətini daxil edin',
        deliveryPrice: 'Çatdırılma qiymətini daxil edin',
        productType: 'Məhsul tipini seçin',
        newBasket: 'Yeni səbət seçin',
        name: 'Malın adı',
        price: 'Qiyməti',
        quantity: 'Miqdarı',
        newParcel: 'Yeni koli seçin',
      },
    },
    orders: {
      orders: 'Sifarişlər',
      order: 'Sifariş',
    },
    dashboard: {
      dashboard: 'Piştaxta',
      onn3Camera: 'ONN3 Kamera',
      declarationStatusStatistics: 'Bağlama statusu üzrə statistika',
      declarationExecutorStatistics: 'Bağlama icraçıları üzrə statistika',
    },
    flights: {
      new: 'Yeni uçuş',
      flights: 'Uçuşlar',
      withDispatch: 'Depeşli',
      withoutDispatch: 'Depeşsiz',
      actions: {
        printWaybill: 'Yol vərəqəsi çap et',
        downloadExcel: 'Excel endir',
      },
      manifests: {
        bulk: 'Toplu manifest',
        bulkWithBags: 'Toplu manifest (kisələrlə)',
        bulkWithBagsExcel: 'Toplu manifest (kisələrlə excel)',
        manifest: 'Manifest',
        manifests: 'Manifestlər',
        withBags: 'Manifest (kisələrlə)',
        allBags: 'Toplam kisə',
        fullBags: 'Dolu kisə',
        emptyBags: 'Boş kisə',
        foundDeclarations: 'Tapılmış bağlamalar',
        notFoundDeclarations: 'Tapılmamış bağlamalar',
      },
      fields: {
        airWaybillNumber: 'Aviaqaimə nömrəsi',
        packagingLimit: 'Paketləmə limiti',
        completion: 'Tamamlanma',
        totalWeight: 'Ümumi çəki',
        openedAt: 'Açılma vaxtı',
        endedAt: 'Bağlamna vaxtı',
      },
      placeholders: {
        name: 'Uçuşun adını daxil edin',
        airWaybillNumber: 'Aviaqaimə nömrəsini daxil edin',
        packagingLimit: 'Paketləmə limitini daxil edin',
      },
    },
    acceptance: {
      acceptance: 'Qəbul',
      thereAreAcceptancesThatCantSave: 'Yadda saxlanıla bilməyən qəbullar var',
    },
    supports: {
      supports: 'Müraciətlər',
      fields: {
        category: {
          name: 'Müraciət növü',
        },
        messages: 'Mesajlar',
      },
    },
    refunds: {
      refunds: 'İadələr',
      actions: {
        printSticker: 'Sticker çap et',
      },
      confirm: {
        delete: 'İadəni silməyə əminsiniz?',
        update: 'İadəni dəyişməyə əminsiniz?',
      },
      expressions: {
        noData: 'Qeyd olunmayıb',
        seeDocument: 'Sənədə bax',
      },
      pages: {
        create: 'Yeni iadə əlavə et',
        update: 'İadəni redaktə et',
      },
      placeholders: {
        refundNumber: 'İadə nömrəsini daxil edin...',
        cargoCompany: 'Karqo şirkətini seçin',
        productPrice: 'Məhsulun qiymətini daxil edin...',
        direction: 'İstiqamət daxil edin...',
        productType: {
          name: 'Məhsul tipini seçin',
        },
      },
      fields: {
        refundNumber: 'İadə nömrəsi',
        cargoCompany: 'Kargo şirkəti',
        productPrice: 'Məhsulun qiymətini',
        direction: 'İstiqamət',
        productType: {
          name: 'Məhsul tipi',
        },
      },
    },
    dns: {
      dns: 'BBS',
      accept: 'Bağlamaları qəbul etməyə əminsinizmi?',
      autoAcceptance: {
        short: 'Avto qəbul',
        autoPrint: 'Avto çap',
        branchCheck: 'Filial yoxlanışı',
        autoAcceptance: 'BBS avto qəbul',
        parcelRequired: 'Əməliyyatı aparmaq üçün koli seçilməlidir',
      },
      notificationTitleWithTrackCode: '#{{trackCode}} izləmə kodlu bağlamanın bildirişləri',
      tasks: {
        titleWithTrackCode: '#{{trackCode}} izləmə kodlu bağlamanın BBS taskları',
        beingApproved: 'Task təsdiq edilir...',
        beenApproved: 'Task təsdiq edildi...',
        fields: {
          addsToForeignWarehouse: 'Anbara əlavə edən',
          type: 'Task tipi',
          status: 'Task statusu',
          branch: 'Filial',
        },
      },
    },
    customs: {
      short: 'DGK',
      relatedProcesses: 'DGK ilə bağlı proseslər',
    },
    barcodes: {
      confirm: {
        deleteOne: 'Seçilmiş barkodu silməyə əminsiniz?',
        deleteMany: 'Seçilmiş barkodları silməyə əminsiniz?',
      },
    },
    logs: {
      logs: 'Loqlar',
      action: 'Əməliyyat',
    },
    amazonOtp: {
      amazonOtp: 'Amazon OTP',
    },
    baskets: {
      baskets: 'Səbətlər',
      basket: 'Səbət',
      targetBasket: 'Transfer ediləsi səbət',
      transferSuccess: 'Səbət uğurla transfer edildi',
      notselected: 'Səbət seçilməlidir...',
      change: 'Bağlamanın səbətini dəyiş',
      confirm: {
        delete: 'Səbəti silməyə əminsiniz?',
        update: 'Səbəti dəyişməyə əminsiniz?',
        close: 'Səbəti bağlamaq istədiyinizə əminsinizmi?',
      },
      placeholders: {
        name: 'Səbət adını daxil edin',
      },
      transfer: 'Transfer',
      closeWithName: '{{name}}. səbəti bağla',
      choose: 'Səbət seç',
      operations: 'Səbət əməliyyatları',
      choosePlaceholder: 'Səbət seç',
      basketRequirement: 'Bağalamarı transfer etmək üçün səbət seçməlisiniz',
    },
    parcels: {
      parcel: 'Koli',
      parcels: 'Kolilər',
      closeWithName: '{{name}}. kolini bağla',
      open: 'Koli aç',
      changeStatus: 'Statusu dəyiş',
      choose: 'Koli seç',
      edit: 'Koli dəyiş',
      currentWithName: 'Cari koli - {{name}}',
      operations: 'Koli əməliyyatları',
      closeConfirm: 'Kolini bağlamaq istədiyinizə əminsinizmi?',
      clearConfirm: 'Kolini təmizləmək istədiyinizə əminsinizmi?',
      change: 'Bağlamanın kolisini dəyiş',
    },
    compare: {
      comparison: 'Müqayisə',
      barcodes: 'Barkodlar',
      foundedBarcodes: 'Tapılan barkodlar',
      missingBarcodes: 'Tapılmayan barkodlar',
      balanceOut: 'Kredit balansı bitib',
      balance: 'Balans',
    },
  },
};
