import { ApiResult } from '../../../shared/utils/api-result';
import { NextTableServerSideData } from '../../../shared/modules/next-table/types';
import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import { appendToFormData } from '../../../shared/utils/apply-to-form-data';
import { baseErrorParser } from '../../../shared/helpers';

import { CustomsMappers } from '../mappers';
import { DnsTask, DnsTaskAcceptFailedResult } from '../types/dns';
import { DnsMappers } from '../mappers/dns';
import { translate } from '../../../infra/i18n/i18n';

export class DnsService {
  public static async getAll(
    query: Record<string, any>,
  ): Promise<ApiResult<200, DnsTask[], NextTableServerSideData> | ApiResult<400 | 500 | 422, { key: string; message: string }>> {
    const url = urlMaker('/api/warehouse/customs_tasks', query);

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data, total } = await response.json();
        return new ApiResult(200, CustomsMappers.Dns.manyFromApi(data), { total });
      } else if (response.status === 400) {
        const { errors } = await response.json();
        return new ApiResult(400, { key: Object.keys(errors)[0], message: Object.values(errors).flat().join('. ') }, null);
      } else if (response.status === 422) {
        const { errors } = await response.json();
        return new ApiResult(400, { key: Object.keys(errors)[0], message: Object.values(errors).flat().join('. ') }, null);
      } else {
        const { errors } = await response.json();
        return new ApiResult(400, { key: Object.keys(errors)[0], message: Object.values(errors).flat().join('. ') }, null);
      }
    } catch (e) {
      return new ApiResult(500, { message: translate('common.errors.networkFailed'), key: '' }, null);
    }
  }

  public static async getOneById(id: string | number): Promise<ApiResult<200, DnsTask> | ApiResult<400 | 500, string>> {
    const url = urlMaker('/api/warehouse/customs_tasks/info', { customs_task_id: id });

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();
        return new ApiResult(200, CustomsMappers.Dns.oneFromApi(data), null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async accept(
    id: number | string,
  ): Promise<
    | ApiResult<200, string>
    | ApiResult<422, { error: string[]; status: string }>
    | ApiResult<400 | 500, string>
    | ApiResult<411, DnsTaskAcceptFailedResult>
  > {
    const url = urlMaker('/api/warehouse/customs_tasks/execute');
    const body = new FormData();
    appendToFormData({ customs_task_id: id }, body);

    try {
      const response = await caller(url, { method: 'POST', body });

      let errorStatus = '';
      if (response.ok) {
        return new ApiResult(200, errorStatus, null);
      } else if (response.status === 422) {
        const { errors } = await response.json();
        errorStatus = 'null_list';
        if (errors.different_branch_id || errors.open_box_error) errorStatus = 'different_branch_id';
        if (errors.already_accepted) errorStatus = 'already_accepted';
        return new ApiResult(422, { error: baseErrorParser(errors), status: errorStatus }, null);
      } else if (response.status === 400) {
        const data = await response.json();
        const mappedData = DnsMappers.acceptFailResult(data);

        return new ApiResult(411, mappedData, null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }

  public static async excelExport(
    filters: object,
  ): Promise<ApiResult<200, any> | ApiResult<422, string[]> | ApiResult<400 | 500, string> | ApiResult<411, DnsTaskAcceptFailedResult>> {
    const url = urlMaker('/api/warehouse/customs_tasks/export', filters);

    try {
      const response = await caller(url);

      if (response.ok) {
        const blob = await response.blob();
        return new ApiResult(200, blob, null);
      } else if (response.status === 400 || response.status === 422) {
        const { errors } = await response.json();

        return new ApiResult(422, baseErrorParser(errors), null);
      } else if (response.status === 422) {
        const data = await response.json();
        const mappedData = DnsMappers.acceptFailResult(data);

        return new ApiResult(411, mappedData, null);
      } else {
        return new ApiResult(400, translate('common.errors.operationFailed'), null);
      }
    } catch (e) {
      return new ApiResult(500, translate('common.errors.networkFailed'), null);
    }
  }
}
