import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Column } from 'react-table';
import { Button, message, Modal, Select, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import * as Icons from '@ant-design/icons';
import moment from 'moment';

import { nextTableColumnGenerator, nextTableColumns } from '../../../shared/modules/next-table/helpers/next-table-columns';
import { StopPropagation } from '../../../shared/components/stop-propagation';

import { DnsTask } from '../types/dns';
import { CustomsService } from '../services';
import { localURLMaker } from '../../../shared/utils/url-maker';
import { Constants } from '../../../shared/constants';
import { ParcelContext } from '../../parcels/contexts';
import { printDeclarationsWaybillUseCase } from '../../declarations/use-cases/print-waybill';
import { printDeclarationsProformaInvoiceUseCase } from '../../declarations/use-cases/print-proforma-invoice';
import { useTranslation } from 'react-i18next';
import { CountryContext } from '../../country';
import { DnsTableContext } from '../context';
import { useTinyBaskets } from '../../baskets/hooks/use-tiny-baskets';
import { SettingsContext } from '../../settings/context';
import { useGetBranchesQuery } from '../../branches/hooks/use-get-branches-query';
import { playSound } from '../utils/play-sound';

export const useDnsTable = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentParcel } = useContext(ParcelContext);
  const { handleChangeFilterById } = useContext(DnsTableContext);
  const baskets = useTinyBaskets();
  const [country] = useContext(CountryContext);
  const { getCountryId } = useContext(SettingsContext)
  const branches = useGetBranchesQuery();

  const getRowProps = useCallback(
    (id) => ({
      onDoubleClick: () => history.push(localURLMaker('/customs/dns/:id/details', { id })),
    }),
    [history],
  );

  useEffect(() => {
    handleChangeFilterById('country_id', country.selected ? getCountryId(country.selected) : undefined);
  }, [handleChangeFilterById, country.selected, getCountryId]);

  const columns = useMemo<Column<DnsTask>[]>(
    () => [
      {
        ...nextTableColumnGenerator(110, 'px'),
        id: 'accept-action',
        sortable: false,
        filterable: false,
        Cell: ({ row: { original } }) => {
          const accept = useCallback(async () => {
            const action = async () => {
              const result = await CustomsService.Dns.accept(original.id);

              if (result.status === 200) {
                message.success('Bağlama təsdiq edildi.');
              } else if (result.status === 411) {
                const { id, reason } = result.data;

                history.push(localURLMaker('/customs/dns/:id/details', { id }));

                Modal.warning({ title: t('common.errors.operationFailed'), content: reason });
              } else {
                if (typeof result.data === 'string') {
                  message.error(result.data);
                } else {
                  message.error(result.data.error);
                  if (result.data.status) playSound(result.data.status);
                }
              }
            };

            Modal.confirm({
              title: t('common.actions.accept'),
              content: t('dns.accept'),
              onOk: action,
            });
          }, [original.id]);

          return (
            <StopPropagation>
              <Button size='small' disabled={original.status.id !== 37 || !currentParcel} onClick={accept} icon={<Icons.CheckCircleOutlined />}>
                {t('common.actions.accept')}
              </Button>
            </StopPropagation>
          );
        },
      },
      {
        ...nextTableColumns.actions,
        accessor: (row) => row.declaration.id,
        id: 'print-waybill',
        filterable: false,
        sortable: false,
        Cell: ({ cell: { value } }) => {
          const printWaybill = useCallback(async () => {
            await printDeclarationsWaybillUseCase(value);
          }, [value]);

          return (
            <StopPropagation>
              <Tooltip title={t('declarations.waybill')} mouseLeaveDelay={0}>
                <Button size='small' icon={<Icons.RocketOutlined />} onClick={printWaybill} />
              </Tooltip>
            </StopPropagation>
          );
        },
      },
      {
        ...nextTableColumns.actions,
        accessor: (row) => row.declaration.id,
        id: 'print-proforma',
        filterable: false,
        sortable: false,
        Cell: ({ cell: { value } }) => {
          const printProforma = useCallback(async () => {
            await printDeclarationsProformaInvoiceUseCase([value]);
          }, [value]);

          return (
            <StopPropagation>
              <Tooltip title={t('declarations.proforma')} mouseLeaveDelay={0}>
                <Button size='small' icon={<Icons.FileOutlined />} onClick={printProforma} />
              </Tooltip>
            </StopPropagation>
          );
        },
      },
      {
        ...nextTableColumns.smaller,
        accessor: (row) => row.id,
        id: 'id',
        Header: t('common.fields.id'),
      },
      {
        ...nextTableColumns.small,
        accessor: (row) => row.declaration.user.id,
        id: 'user_id',
        Header: t('common.fields.user.id'),
      },
      {
        ...nextTableColumns.normal,
        accessor: (row) => row.branch.name,
        id: 'branch_id',
        Header: t('dns.tasks.fields.branch'),
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select style={{ width: '100%' }} allowClear={true} value={filterValue} onChange={setFilter}>
              {branches.data?.map(elem =>
                <Select.Option key={elem.id} value={elem.id.toString()}>{elem.name}</Select.Option>
              )}
            </Select>
          );
        },
      },
      {
        accessor: (row) => row.declaration.user.name,
        id: 'user_name',
        Header: t('common.fields.user.name'),
      },
      {
        ...nextTableColumns.normal,
        accessor: (row) => row.declaration.trackCode,
        id: 'track_code',
        Header: t('declarations.fields.trackCode'),
      },
      {
        accessor: (row) => row.declaration.updatedBy?.name,
        id: 'changer_name',
        Header: t('dns.tasks.fields.addsToForeignWarehouse'),
      },
      {
        ...nextTableColumns.normal,
        accessor: (row) => row.createdAt,
        id: 'created_at_humanized',
        filterable: false,
        sortable: false,
        Cell: ({ cell: { value } }) => {
          return moment(value, Constants.DATE_TIME).fromNow();
        },
      },
      {
        ...nextTableColumns.small,
        accessor: (row) => row.declaration.weight,
        id: 'weight',
        Header: t('common.fields.weight'),
        Cell: ({ cell: { value } }) => !!value && `${value.toFixed(2)} kq`,
      },
      {
        ...nextTableColumns.normal,
        accessor: (row) => row.declaration.basket?.id,
        id: 'basket_id',
        Header: t('baskets.basket'),
        Filter: ({ column: { filterValue, setFilter } }) => (
          <Select
            showSearch
            disabled={baskets.loading}
            loading={baskets.loading}
            style={{ width: '100%' }}
            allowClear={true}
            value={filterValue}
            onChange={setFilter}
          >
            {baskets.data.map((basket) => (
              <Select.Option key={basket.id} value={basket.id.toString()}>
                {basket.name}
              </Select.Option>
            ))}
          </Select>
        ),
        Cell: ({ row: { original } }) => original.declaration.basket?.name || null,
      },
      {
        ...nextTableColumns.smaller,
        accessor: (row) => row.declaration.quantity,
        id: 'quantity',
        Header: t('common.fields.quantity'),
      },
      {
        ...nextTableColumns.normal,
        accessor: (row) => row.declaration.status.name,
        id: 'declaration_state_name',
        Header: t('declarations.fields.statusShort'),
      },
      {
        ...nextTableColumns.normal,
        accessor: (row) => row.declaration.productType.name,
        id: 'product_type_name',
        Header: t('declarations.fields.productType.name'),
      },
      {
        ...nextTableColumns.small,
        accessor: (row) => row.action,
        id: 'action',
        Header: t('common.fields.type'),
      },
      {
        ...nextTableColumns.normal,
        accessor: (row) => row.status.name,
        id: 'state_id',
        Header: t('dns.tasks.fields.status'),
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select style={{ width: '100%' }} allowClear={true} value={filterValue} onChange={setFilter}>
              <Select.Option value='37'>Təsdiq gözləyir</Select.Option>
              <Select.Option value='38'>İcra edilib</Select.Option>
            </Select>
          );
        },
      },
      {
        ...nextTableColumns.date,
        accessor: (row) => row.createdAt,
        id: 'created_at',
        Header: t('common.fields.createdAt'),
      },
    ],
    [baskets.data, baskets.loading, currentParcel, history, t, branches.data],
  );

  return { columns, getRowProps };
};
