import Cookies from 'js-cookie';

export const caller = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
  let accessToken: string | null = (await Cookies.get('accessToken')) || null;

  const additionalHeaders: HeadersInit = {
    Accept: 'application/json',
    Authorization: accessToken ? `Bearer ${accessToken}` : '',
    lang: localStorage.getItem('i18nextLng') || 'az',
  };
  let country_id = localStorage.getItem('warehouse.country_id');
  if (country_id) additionalHeaders['country-id'] = country_id;

  const finalInit: RequestInit = {
    ...init,
    headers: {
      ...(init ? init.headers : {}),
      ...additionalHeaders,
    },
  };

  return fetch(input, finalInit);
};
