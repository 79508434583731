import { useCallback, useContext, useMemo, useState } from 'react';
import { FormikHelpers } from 'formik';
import { message, Modal } from 'antd';

import { printDeclarationsWaybillUseCase } from '../../declarations/use-cases/print-waybill';

import { DnsService } from '../services/dns';
import { DnsTask } from '../types/dns';
import { DnsAcceptanceFormData } from '../types/dns-acceptance';
import { CustomsService } from '../services';
import { useQuery } from '../../../shared/modules/use-query/hooks';
import { ParcelService } from '../../parcels/services';
import { ParcelContext } from '../../parcels/contexts';
import { useTranslation } from 'react-i18next';
import { CountryContext } from '../../country';
import { SettingsContext } from '../../settings/context';
import { playSound } from '../utils/play-sound';
import { ErrorAudioUrls } from '../constants/custom_error_audio';

export const useDnsAcceptance = () => {
  const [dnsTasks, setDnsTasks] = useState<DnsTask[]>([]);
  const { currentParcel } = useContext(ParcelContext);
  const country = useContext(CountryContext);
  const settings = useContext(SettingsContext);
  const [customErrors, setCustomErrors] = useState<'initial' | 'yes' | 'no'>('initial');
  const { t } = useTranslation();

  const resetDnsTasks = useCallback(() => {
    setDnsTasks([]);
  }, []);

  const currentParcelFields = useQuery<{
    weight: number;
    count: number;
    data: { basket_name: string; id: number; track_code: number; user_name: string; weight: number }[];
  }>(
    useCallback(async () => {
      if (!currentParcel) {
        throw new Error(t('common.errors.parcelNotSelected'));
      }
      const result = await ParcelService.getWeight(currentParcel.id);
      if (result.status === 200) {
        return result.data;
      } else {
        throw new Error(result.data);
      }
    }, [currentParcel, t]),
    useMemo(
      () => ({
        initialData: { weight: 0, count: 0 },
        enabled: !!currentParcel,
      }),
      [currentParcel],
    ),
  );

  const { reFetch: reFetchCurrentParcelWeight } = currentParcelFields;

  const acceptTask = useCallback(
    async (id: string | number) => {
      message.loading({ content: t('dns.tasks.beingApproved'), duration: null });
      const result = await CustomsService.Dns.accept(id);
      message.destroy();

      if (result.status === 200) {
        message.success(t('dns.tasks.beenApproved'));
        setDnsTasks((tasks) => tasks.filter((task) => task.id !== id));
      } else if (result.status === 411) {
        const { reason } = result.data;

        Modal.warning({ title: t('common.errors.operationFailed'), content: reason });
      } else {
        message.error(result.data);
      }
    },
    [t],
  );

  const initialValues = useMemo<DnsAcceptanceFormData>(() => {
    let branchCheck = false;
    if (localStorage.getItem('branch_check') === 'true') branchCheck = true;
    if (settings.data && country[0].selected) {
      const ctr = settings.data.countries.find((elem) => elem.abbr === country[0].selected);
      if (ctr) branchCheck = !!ctr.box;
    }
    return {
      trackCode: '',
      stateId: '37',
      autoAccept: localStorage.getItem('acceptance.autoAccept') === '1',
      autoPrint: localStorage.getItem('acceptance.autoPrint') === '1',
      branchCheck,
    };
  }, [settings, country]);

  const onSubmit = useCallback(
    async (values: DnsAcceptanceFormData, helpers: FormikHelpers<DnsAcceptanceFormData>) => {
      setCustomErrors('initial');
      const result = await DnsService.getAll({
        track_code: values.trackCode.replace('FX', ''),
        approve: values.autoAccept,
        branch_check: values.branchCheck ? 1 : 0,
      });

      helpers.setFieldValue('trackCode', '');

      if (result.status !== 200) {
        setCustomErrors('yes');
        Modal.error({
          content: result.data.message,
          okText: t('common.actions.close'),
          className: 'modal_content_big',
        });
        if (result.status === 422 || result.status === 400) {
          const url = ErrorAudioUrls[result.data.key] || 'null_list';
          playSound(url);
        }
        setTimeout(() => {
          Modal.destroyAll();
        }, [3000]);
        return;
      }

      const data = result.data;

      if (!data.length) {
        Modal.warning({
          content: t('common.errors.dnsNotFound'),
          okText: t('common.actions.close'),
          className: 'modal_content_big',
        });
        playSound(ErrorAudioUrls.null_list);
        setTimeout(() => {
          Modal.destroyAll();
        }, [3000]);
        return;
      } else {
        playSound(ErrorAudioUrls.success);
        setCustomErrors('no');
      }

      if (data.length === 1 && values.autoPrint) {
        await printDeclarationsWaybillUseCase(data[0].declaration.id);
      }

      if (data.length > 1 || (data.length === 1 && !values.autoAccept)) {
        setDnsTasks(data);
      }

      await reFetchCurrentParcelWeight();
    },
    [reFetchCurrentParcelWeight, t],
  );

  return {
    initialValues,
    onSubmit,
    dnsTasks,
    resetDnsTasks,
    acceptTask,
    currentParcelFields: currentParcelFields.data,
    customErrors,
    setCustomErrors,
  };
};
